import {createSelector} from 'reselect';

export const createAction = (type, asyncAction) => {
    if (asyncAction) return createAsyncAction(type, asyncAction);
    const action = payload => ({type, payload});
    action.toString = () => type;
    return action;
};

export const createAsyncAction = (type, asyncAction) => {
    const successType = `${type}_SUCCESS`;
    const requestAction = createAction(`${type}_REQUEST`);
    const failureAction = createAction(`${type}_FAILURE`);

    const action = payload => async (dispatch, getState) => {
        dispatch(requestAction(payload));
        try {
            const result = await asyncAction({payload, dispatch, getState});
            dispatch({type: successType, payload: result});
            return result;
        } catch (error) {
            dispatch(failureAction(error));
            throw error;
        }
    };

    action.toString = () => successType;
    action.request = requestAction;
    action.failure = failureAction;

    return action;
};

export const createReducer = (initial, handlers) => {
    return (state = initial, action) => {
        if (handlers[action.type]) state = handlers[action.type](state, action);
        return state;
    };
};

export const createDenormalizingSelector = (dataSelector, key, options) => createSelector(
    [dataSelector, ...options.map(option => option.selector)],
    (data, ...sources) => data[key][0].data.map(datum => {
        options.forEach((option, index) => {
            if (Array.isArray(datum[option.srcKey])) {
                datum[option.dstKey] = datum[option.srcKey].map(id => (
                    sources[index].find(related => related.id === id)
                ));
            } else {
                datum[option.dstKey] = sources[index].find(related => related.id === datum[option.srcKey]);
            }
        });
        return datum;
    }),
);
