import uuid from 'uuid';
import callApi from 'api';
import {createAction} from 'core/store/utils';
import {UUID, getApiUrl, camelizeWorkflow} from 'api/utils';

export const activateWorkflow = createAction('ACTIVE_WORKFLOW:ACTIVATE');

export const loadWorkflow = createAction(
    'ACTIVE_WORKFLOW:LOAD',
    async ({payload, getState, dispatch}) => {
        const {auth: {token}} = getState();

        const url = getApiUrl(`api/workflow/v1/workflow/${payload}/`);
        const options = {headers: {'Authorization': `JWT ${token}`}};
        const response = await callApi(url, options, dispatch);
        const workflow = await response.json();

        return dispatch(activateWorkflow(camelizeWorkflow(workflow)));
    },
);

export const duplicateWorkflow = createAction(
    'ACTIVE_WORKFLOW:DUPLICATE',
    async ({payload, getState, dispatch}) => {
        const {auth: {token}} = getState();

        const url = getApiUrl(`api/workflow/v1/workflow/${payload}/`)
        const options = {headers: {'Authorization': `JWT ${token}`}}
        const response = await callApi(url, options, dispatch);

        const original = await response.json();
        const duplicate = camelizeWorkflow({
            ...original,
            id: uuid.v4(),
            workflow_id: UUID(),
            chunked_workflow_id: '',
            name: `${original.name} (Duplicate)`,
        });

        return dispatch(activateWorkflow(duplicate));
    }
)

export const createWorkflow = createAction('ACTIVE_WORKFLOW:CREATE');

export const unloadWorkflow = createAction('ACTIVE_WORKFLOW:UNLOAD');
export const addActionToConfig = createAction('ACTIVE_WORKFLOW:CREATE_ACTION');
export const saveActionConfig = createAction('ACTIVE_WORKFLOW:SAVE_ACTION_CONFIG');
export const removeActionConfig = createAction('ACTIVE_WORKFLOW:REMOVE_ACTION_CONFIG');
export const updateActiveWorkflowProperty = createAction('ACTIVE_WORKFLOW:UPDATE_WORKFLOW');
