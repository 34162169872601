import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {hot} from 'react-hot-loader/root';
import {Link} from 'react-router-dom';
import {autobind} from 'core-decorators';
import {
    createNewWorkflow,
    duplicateWorkflow,
    fetchWorkflows,
    updateWorkflowProperty,
} from 'core/store/workflows/actions';

import Table from 'components/table';
import Button from 'components/button';
import Spinner from 'components/spinner';
import AppLayout from 'components/layout';
import PageHeader from 'components/page-header';

import columns from './columns.json';
import styles from './styles.css';

@connect(
    (state) => ({workflows: state.workflows}),
    (dispatch) => bindActionCreators({
        createNewWorkflow,
        duplicateWorkflow,
        fetchWorkflows,
        updateWorkflowProperty,
    }, dispatch),
)
@hot
@autobind
export default class Home extends Component {
    render() {
        const rows = (this.props.workflows || [])
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(workflow => ({
                name: this.renderWorflowName(workflow),
                actions: this.renderButtons(workflow),
            }));

        return (
            <AppLayout >
                <PageHeader title="Workflow Admin">
                    <Link to="/create">
                        <Button color="secondary">New Workflow</Button>
                    </Link>
                </PageHeader>
                {this.props.workflows ? (
                    <Table
                        rows={rows}
                        columns={columns}
                        onEmptyStateClick={this.createWorkflow}
                    />
                ): (
                    <Spinner />
                )}
            </AppLayout>
        );
    }

    renderWorflowName(workflow) {
        return <p><Link to={`/edit/${workflow.id}`}>{workflow.name}</Link></p>;
    }

    renderButtons(workflow) {
        return (
            <div className={styles.buttons}>
                <Link to={`/edit/${workflow.id}`}>
                    <Button appearance='outline' color='gray'>Edit</Button>
                </Link>
                <Link to={`/create?duplicate=${workflow.id}`}>
                    <Button appearance='outline' color='gray'>Duplicate</Button>
                </Link>

            </div>
        );
    }

    componentWillMount() {
        this.props.fetchWorkflows();
    }

    createWorkflow() {
        this.props.history.push(`/create`);
    }
}
