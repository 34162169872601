import callApi from 'api';
import {createAction} from 'core/store/utils';
import {getApiUrl, camelizeObjectKeys} from 'api/utils';

export const loadActionConfigs = createAction('ACTION_CONFIGS:LOAD_ACTION_CONFIGS')

export const fetchActionConfigs = createAction(
    'ACTION_CONFIGS:FETCH_AVAILABLE_ACTIONS',
    async ({dispatch, getState}) => {
        const {auth: {token}} = getState();
        const url = getApiUrl('api/workflow/v1/actions/');
        const options = {headers: {'Authorization': `JWT ${token}`}};
        const response = await callApi(url, options, dispatch);

        const availableActions = await response.json();
        const camelized = Object.keys(availableActions).reduce((camelized, key) => (
            Object.assign(camelized, {[key]: camelizeObjectKeys(availableActions[key])})
        ), {});

        dispatch(loadActionConfigs(camelized));
    }
);
