import {customProperties} from './colors';


// const toCustomProperty = key => `--${key.replace(/([A-Z0-9])/g, v => `-${v.toLowerCase()}`)}`;
const fromCustomProperty = key => key.substr(2).replace(/(-.)/g, v => v[1].toUpperCase());

export const colors = Object.entries(customProperties).reduce((result, [key, value]) => ({
    ...result,
    [fromCustomProperty(key)]: value,
}), {});
