import { createReducer } from '../utils';

import {
  saveWorkflow,
  removeWorkflow,
  duplicateWorkflow,
  fetchWorkflows,
  updateWorkflowProperty,
} from './actions';


export default createReducer([], {
  [fetchWorkflows]: (state, {payload}) => {
    return payload;
  },
  [saveWorkflow]: (state, {payload}) => {
    return state.map((workflow) => {
      if (workflow.id === payload.id) return payload;
      return workflow;
    });
  },
  [removeWorkflow]: (state, {payload}) => {
    return state.filter((workflow) => workflow.id !== payload);
  },
  [updateWorkflowProperty]: (state, {payload}) => {
    return state.map((workflow) => {
      if (workflow.id === payload.id) return {...workflow, [payload.property]: payload.value};
      return workflow;
    });
  },
  [duplicateWorkflow]: (state, {payload}) => {
    const {sourceId, targetId} = payload;
    const actionToDupe = state.find((workflow) => workflow.id === sourceId);
    if (!actionToDupe) throw new Error('ActionNotFound');
    return [...state, {...actionToDupe, id: targetId}];
  },
});
