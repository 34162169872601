import diacritics from 'diacritics';
import UAParser from 'ua-parser-js';
import qs from 'qs';


export const range = length => [...Array(length).keys()];
export const isMobile = () => new UAParser().getResult().device.type === 'mobile';

export const cleanString = value => diacritics.remove(value).toLowerCase().trim();

export const toQueryString = obj => qs.stringify(obj);
export const fromQueryString = query => qs.parse(query);

export const setIsEquivalent = (setA, setB) => {
    return setA.size === setB.size && Array.from(setA).every(
        (element) => setB.has(element)
    );
};

