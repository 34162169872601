import {createAction} from '../utils';

export const discardActionConfig = createAction('ACTIVE_ACTION_CONFIG:DISCARD');
export const activateActionConfig = createAction('ACTIVE_ACTION_CONFIG:ACTIVATE');

export const updateActiveActionProperty = createAction('ACTIVE_ACTION_CONFIG:UPDATE_PROPERTY');

export const loadActionConfig = createAction(
    'ACTIVE_ACTION_CONFIG:LOAD',
    ({payload, getState, dispatch}) => {
        const {activeWorkflow} = getState();
        const action = activeWorkflow.actions.find((action) => action.clientId === payload);

        if (!action) {
            throw new Error('ActionNotFound');
        }

        return dispatch(activateActionConfig(action));
    }
);
