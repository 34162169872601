import uuid from 'uuid';
import React,  {Component}  from 'react';
import PropTypes from 'prop-types';

import styles from './styles.css';

class ErrorList extends Component {
    static proptypes = {
        errors: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
        errors: [],
    }

    render () {
        const {errors} = this.props;
        if (!errors.length) return null;
        return (
            <ul className={styles.root}>
                <h2>{this.props.children}</h2>
                {this.props.errors.map((message) => <li key={[message, uuid.v4()].join('_')}>{message}</li>)}
            </ul>
        );
    }
}

export default ErrorList;
