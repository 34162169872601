import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';

import ErrorBoundary from './errors/error-boundary';
import PageTracker from './analytics/page-tracker';
import AppContainer from './layout/app-container';

import {configureStore} from './store';
import {initializeErrorReporting} from './errors';
import {initializeURLs} from './urls';


const appRuntime = {};

export const ConfigContext = React.createContext({});

export const initialize = options => {
    if (appRuntime.initialized) throw new Error('App has already been initialized.');
    initializeErrorReporting(options);
    initializeURLs(options);
    const store = configureStore();

    appRuntime.store = store;
    appRuntime.config = options;
    appRuntime.initialized = true;
};

export const render = RootComponent => {
    if (!appRuntime.initialized) throw new Error('App must be initialized before calling render.');

    ReactDOM.render(
        <BrowserRouter>
            <ConfigContext.Provider value={appRuntime.config}>
                <Provider store={appRuntime.store}>
                    <PageTracker trackingId={appRuntime.config.trackingId}>
                        <ErrorBoundary>
                            <AppContainer>
                                <RootComponent />
                            </AppContainer>
                        </ErrorBoundary>
                    </PageTracker>
                </Provider>
            </ConfigContext.Provider>
        </BrowserRouter>,
        document.getElementById('root'),
    );
};
