import uuid from 'uuid';

import {createReducer} from '../utils';

import {
    addOutputHandler,
    removeOutputHandler,
    discardOutputHandlers,
    loadActiveOutputHandlers,
    updateOutputHandlerProperty,
} from './actions';

const initialState = [];

export const baseOutputHandler = {
    outputValue: null,
    actionId: null,
    identifier: null,
};

export default createReducer(initialState, {
    [discardOutputHandlers]: () => initialState,
    [loadActiveOutputHandlers]: (_, {payload}) => payload.map(handler => ({...handler, clientId: uuid.v4()})),
    [updateOutputHandlerProperty]: (state, {payload}) => {
        return state.map(handler => {
            if (handler.clientId === payload.clientId) {
                return {
                    ...handler,
                    [payload.property]: payload.value,
                }
            }
            return {...handler};
        });
    },
    [addOutputHandler]: (state) => {
        return state.concat([{...baseOutputHandler, clientId: uuid.v4()}])
    },
    [removeOutputHandler]: (state, {payload}) => {
        return state.filter(handler => {
            return handler.clientId !== payload.clientId;
        });
    },
});
