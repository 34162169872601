import fetchPonyfill from 'fetch-ponyfill';
import {logout} from 'core/store/auth/actions';

const {fetch} = fetchPonyfill();

const callApi = async (url, options, dispatch) => {
    try {
        const response = await fetch(url, options);
        if (dispatch && response.status === 403) dispatch(logout());
        return response;
    } catch (error) {
        alert('There was an error, please try again later.');
        dispatch(logout());
    };
}

export default callApi;
