const customProperties = {
    '--brand-blue': '#29668C',
    '--pale-blue': '#EBF8FF',

    // Primary
    // '--primary-light-3': '',
    '--primary-dark-2': '#0476A7',
    '--primary-dark-1': '#099AD8',
    '--primary': '#2EAAE1',
    '--primary-light-1': '#54BBE7',
    '--primary-light-2': '#81CDEF',
    '--primary-light-3': '#9FC6D5',

    // Secondary
    '--secondary-dark-2': '#25846F',
    '--secondary-dark-1': '#2FAA8F',
    '--secondary': '#39CFAE',
    '--secondary-light-1': '#81E0CB',
    '--secondary-light-2': '#A5E9DA',

    // Neutral
    '--white': '#FFF',
    '--black': '#000',
    '--gray-dark-3': '#131313',
    '--gray-dark-2': '#444A4C',
    '--gray-dark-1': '#778084',
    '--gray': '#98A5AB',
    '--gray-light-1': '#B9C9D0',
    '--gray-light-2': '#E5EBED',
    '--gray-light-3': '#F0F5F6',
    '--gray-light-4': '#F8F8F8',

    // System
    '--success-dark-2': '#004000',
    '--success-dark-1': '#005700',
    '--success': '#009500',
    '--success-light-1': '#1FA950',
    '--success-light-2': '#A5DAB2',
    '--success-light-3': '#E4F4E7',
    '--warning-dark-2': '#9E6C00',
    '--warning-dark-1': '#DD9D00',
    '--warning': '#FFC70B',
    '--warning-light-1': '#FFDA72',
    '--warning-light-2': '#FFEBB3',
    '--warning-light-3': '#FFF6DE',
    '--error-dark-2': '#6E0000',
    '--error-dark-1': '#AB0000',
    '--error': '#E30000',
    '--error-light-1': '#EB5056',
    '--error-light-2': '#F7AEAF',
    '--error-light-3': '#FFE5E5',

};

module.exports = {customProperties};
