import React from 'react';
import {Route, Switch} from 'react-router-dom';


const defaultOptions = {exact: true, strict: true};
let routes = [];

export const initializeURLs = options => {
    routes = (options.views || []).map(view => ({
        ...defaultOptions,
        ...view,
    }));
};


export const ActiveView = viewProps => {
    return (
        <Switch>
            {routes
                .map(route => {
                    const Component = route.component;
                    return (
                        <Route
                            key={route.path}
                            path={route.path}
                            exact={route.exact}
                            strict={route.strict}
                            render={route.render || (props => <Component {...viewProps} {...props} />)}
                        />
                    );
                })
            }
        </Switch>
    );
};

export const reverse = (name, params = {}) => {
    const route = routes.find(route => route.name === name);
    if (!route) throw new Error(`Invalid route name: ${name}`);

    let {path} = route;

    // Replace provided params.
    Object.entries(params).forEach(([key, value]) => {
        path = path.replace(`:${key}`, value);
    });

    // Remove optional params.
    path = path.replace(/(:\w+\?)\//g, '');
    return path;
};
