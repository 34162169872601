import {Component} from 'react';
import {withRouter} from 'react-router-dom';


@withRouter
export default class PageTracker extends Component {
    componentWillMount() {
        window.ga = (...args) => window.ga.q.push(args);
        window.ga.q = [];
        window.ga.l = +new Date();
        window.ga('create', this.props.trackingId, 'auto');

        const script = document.createElement('script');
        script.src = 'https://www.google-analytics.com/analytics.js';
        script.async = true;
        document.body.appendChild(script);
    }

    componentDidMount() {
        this.trackPageView();
    }

    componentDidUpdate(prevProps) {
        const newLocation = this.props.location;
        const prevLocation = prevProps.location;
        if (newLocation.pathname !== prevLocation.pathname || newLocation.search !== prevLocation.search) {
            this.trackPageView();
        }
    }

    render() {
        return this.props.children;
    }

    trackPageView() {
        window.ga('send', 'pageview');
    }
}
