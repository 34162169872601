import callApi from 'api';
import {createAction} from 'core/store/utils';
import {getApiUrl, decamelizeWorkflow, camelizeWorkflow} from 'api/utils'

export const removeWorkflow = createAction('WORKFLOWS:REMOVE');
export const createNewWorkflow = createAction('WORKFLOWS:CREATE');
export const duplicateWorkflow = createAction('WORKFLOWS:DUPLICATE');
export const updateWorkflowProperty = createAction('WORKFLOWS:UPDATE_PROPERTY');

export const saveWorkflow = createAction(
    'WORKFLOWS:SAVE',
    async ({payload, dispatch, getState}) => {
        const {auth: {token}} = getState();

        const orderedActionList = payload.actions.map(({actionId, identifier}) => {
            let action = actionId;
            if (identifier) action.concat(':', identifier);
            return action;
        });

        const workflow = decamelizeWorkflow(payload);

        let url = getApiUrl(`api/workflow/v1/workflow/`);
        if (!payload.isNew) url = url.concat(`${workflow.id}/`);

        const method = payload.isNew ? 'POST' : 'PUT';

        const options = {
            method,
            headers: {'Authorization': `JWT ${token}`, 'Content-Type': 'application/json'},
            body: JSON.stringify({
                ...workflow,
                data: JSON.stringify(workflow.data),
                ordered_action_list: JSON.stringify(orderedActionList),
            }),
        };

        const response = await callApi(url, options, dispatch);
        const data = await response.json();

        if (response.ok) {
            return camelizeWorkflow(data);
        } else {
            const error = new Error(data);
            error.data = data;
            throw error;
        }
    }
);

export const fetchWorkflows = createAction(
    'WORKFLOWS:FETCH',
    async ({payload, getState, dispatch}) => {
        const {auth: {token}} = getState();
        const url = getApiUrl('api/workflow/v1/workflow/');
        const options = {method: 'GET', headers: {'Authorization': `JWT ${token}`}};
        const response = await callApi(url, options, dispatch);
        const data = await response.json();

        if (response.ok) {
            return data.results.map(camelizeWorkflow);
        } else {
            const error = new Error(data);
            error.data = data;
            throw error;
        }
    },
);
