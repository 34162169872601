import uuid from 'uuid';
import {UUID} from 'api/utils';
import {createReducer} from '../utils';

import {
    activateWorkflow,
    saveActionConfig,
    addActionToConfig,
    removeActionConfig,
    updateActiveWorkflowProperty,
    unloadWorkflow,
    createWorkflow,
} from './actions';


export const baseAction = {
    identifier: null,
    actionId: '',
    outputHandlers: [],
    workflowArgs: {},
};

const baseWorkflow = {
    isActive: false,
    name: '',
    description: '',
    chunkedWorkflowId: '',
}


const initialState = null;

export default createReducer(initialState, {
    [activateWorkflow]: (_, {payload: {actions, ...workflow}}) => {
        return {
            ...workflow,
            actions: actions.map((action) => ({...action, clientId: uuid.v4() })),
        };
    },

    [createWorkflow]: (state) => {
        // id will come as payload once the thunk gets it from the backend
        const id = uuid.v4();
        return {
            ...baseWorkflow,
          id,
          actions: [],
          workflowId: UUID(),
          chunkedWorkflowId: '',
          userVisible: true,
        };
    },

    [saveActionConfig]: (state, {payload}) => {
        const {clientId, updatedAction} = payload;
        const actions = state.actions.map((action) => {

            if (action.clientId === clientId) {
                return updatedAction;
            }

            return {...action}
        })

        return {...state, actions};
    },

    [removeActionConfig]: (state, {payload}) => {
        const actions = state.actions.filter((action) => {
            return action.clientId !== payload;
        });

        return  {...state, actions}
    },

    [addActionToConfig]: (state, {payload}) => {
        const clientId = payload || uuid.v4();
        return {
            ...state,
            actions: state.actions.concat([{
                ...baseAction,
                clientId
            }])
        }
    },

    [updateActiveWorkflowProperty]: (state, {payload}) => {
        return {
            ...state,
            [payload.property]: payload.value,
        };
    },

    [unloadWorkflow]: () => initialState,
});
