import * as Sentry from '@sentry/browser';


export const initializeErrorReporting = options => {
    if (options.sentryPublicDSN) {
        Sentry.init({
            release: options.version,
            environment: options.environment,
            dsn: options.sentryPublicDSN,
        });
    }
};
