import React, {Component} from 'react';

import styles from './styles.css';

export default class PageHeader extends Component {
    render () {
        return (
            <div className={styles.root}>
                <h1 className={styles.title}>{this.props.title}</h1>
                <div className={styles.tools}>
                    {React.Children.map(this.props.children, (child) => <div className={styles.child}>{child}</div>)}
                </div>
            </div>
        );
    }
}
