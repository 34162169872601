import fetchPonyfill from 'fetch-ponyfill';
import {createAction} from 'core/store/utils';
import {getApiUrl} from 'api/utils';

export const {fetch} = fetchPonyfill();

export const setRefererAddress = createAction('AUTH:SET_REFERER_ADDRESS');

export const login = createAction('AUTH:LOGIN');
export const logout = createAction(
    'AUTH:LOGOUT',
    () => window.history.pushState({}, document.title, window.location.origin),
);

export const getToken = createAction(
    'AUTH:AUTHENTICATE',
    async ({payload, dispatch}) => {
        const {username, password} = payload;

        const url = getApiUrl('api-token-auth/');
        const body = JSON.stringify({username, password});
        const response = await fetch(url, {
            body,
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
        });

        const result = await response.json();
        if (result.token) return dispatch(login({token: result.token}));
    }
);


export const REFRESH_INTERVAL = 50 * 60 * 1000;
export const refreshToken = createAction(
    'AUTH:REFRESH_TOKEN',
    async ({payload, dispatch, getState}) => {
        const {auth: {token}} = getState();
        const url = getApiUrl('api-token-refresh/');
        const body = JSON.stringify({token});
        const response = await fetch(url, {
            body,
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
        });

        const status = response.status;
        const result = await response.json();
        if (result.token) return dispatch(login({token: result.token}));
    },
);

