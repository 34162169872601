import React, {Component} from 'react';
import {autobind} from 'core-decorators';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.css';

@autobind
export default class Table extends Component {
    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })).isRequired,
        rows: PropTypes.arrayOf(PropTypes.object).isRequired,
        rowHeaders: PropTypes.arrayOf(PropTypes.node),
    };

    static defaultProps = {
        rows: [],
    };

    render() {
        return (
            <div className={styles.root}>
                <table className={styles.table}>
                    {this.renderColumnHeaders()}
                    {this.renderRows()}
                </table>
                {!this.props.rows.length && this.renderEmptyState()}
            </div>
        );
    }

    renderColumnHeaders() {
        return (
            <thead>
                <tr className={styles.row}>
                    {this.props.columns.map(column => {
                        const classes = classnames(styles.header, {[styles.center]: column.center});

                        let width;
                        if (column.expand) width = "100%";
                        else if (column.width) width = `${column.width}%`;
                        else width = 'auto';
                        return <th style={{width}} key={column.key} className={classes}> {column.label}</th>
                    })}
                </tr>
            </thead>
        );
    }

    renderRows() {
        const {columns, rows} = this.props;
        return (
            <tbody>
                {Boolean(rows.length) && rows.map((row, index) => {
                    if (React.isValidElement(row)) return row;

                    const rowKey = 'row_' + index;
                    return (
                        <tr className={styles.row} key={rowKey}>
                            {columns.map(column => {
                                const classes = classnames({
                                    [styles.expand]: column.expand,
                                    [styles.center]: column.center,
                                });

                                const width = {
                                    width: column.width ? `${column.width}%` : 'auto',
                                };

                                return (
                                    <td
                                        style={width}
                                        className={classes}
                                        key={rowKey + column.key}>
                                            {row[column.key]}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        );
    }

    renderEmptyState() {
        const {onEmptyStateClick} = this.props;
        return (
            <center>
                <div className={styles.empty}>
                    There are no items in this list yet.&nbsp;
                    {onEmptyStateClick && <span><span className={styles.clickable} onClick={onEmptyStateClick} >Click here</span> to get started!</span>}
                </div>
            </center>
        );
    }
}
