import {combineReducers} from 'redux';
import auth from './auth/reducer';
import workflows from './workflows/reducer';
import actionConfigs from './action-configs/reducer';
import activeWorkflow from './active-workflow/reducer';
import activeActionConfig from './active-action-config/reducer';
import activeOutputHandlers from './active-output-handlers/reducer';

export default combineReducers({
    auth,
    workflows,
    actionConfigs,
    activeWorkflow,
    activeActionConfig,
    activeOutputHandlers,
});
