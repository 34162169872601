import uuid from 'uuid';
import {createReducer} from '../utils';
import {
    discardActionConfig,
    activateActionConfig,
    updateActiveActionProperty,
} from './actions';

export default createReducer(null, {
    [discardActionConfig]: () => null,
    [activateActionConfig]: (state, {payload}) => {
        return {
            ...payload,
            outputHandlers: payload.outputHandlers.map((handler) => ({
                clientId: uuid.v4(),
                ...handler,
            }))
        };
    },

    [updateActiveActionProperty]: (state, {payload}) => {
        const {property, value, argName} = payload;
        if (property === 'workflowArgs') {
            return {
                ...state,
                workflowArgs: Object.assign({}, state.workflowArgs, {[argName]: value}),
            }
        }

        return {
            ...state,
            [property]: value,
        };
    },
});
