import React, {Component} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.css';

export default class OutputHandlerPill extends Component {
    static propTypes = {
        outputHandler: PropTypes.object.isRequired,
    };

    render() {
        const {
            outputValue,
            identifier,
            actionId,
        } = this.props.outputHandler;

        return (
            <div className={styles.pillRoot}>
                <span className={styles.text}>{outputValue}</span>
                <i className={classnames('material-icons', styles.separator)}>chevron_right</i>
                <span className={styles.text}>{actionId}</span>
                {identifier && <span className={styles.separator}>:</span>}
                {identifier && <span className={styles.text}><strong>{identifier}</strong></span>}
            </div>
        );
    }
}
