import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {hot} from 'react-hot-loader/root';
import {autobind} from 'core-decorators';
import {withRouter} from 'react-router-dom';

import {fromQueryString} from 'core/utils';

import {login, refreshToken, REFRESH_INTERVAL, setRefererAddress} from 'core/store/auth/actions';

import {ActiveView} from 'core/urls';
import AuthView from 'views/auth';
import Spinner from 'components/spinner';

import styles from './styles.css';

@connect(
    (state) => ({token: state.auth.token}),
    (dispatch) => bindActionCreators({login, refreshToken, setRefererAddress}, dispatch),
)
@hot
@autobind
@withRouter
export default class App extends Component {
    state = {
        loading: true,
    };

    async componentDidMount() {
        const parameters = fromQueryString(this.props.location.search.replace(/\?/, ''));
        if (parameters.referrer) await this.props.setRefererAddress(parameters.referrer);
        if (!this.props.token && parameters.at) await this.props.login({token: parameters.at});
        this.setState({loading: false});
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.token) {
            clearInterval(this.tokenRefreshInterval);
        } else if (this.props.token !== nextProps.token) {
            this.tokenRefreshInterval = setInterval(this.props.refreshToken, REFRESH_INTERVAL);
        }
    }

    componentWillUnmount() {
        clearInterval(this.tokenRefreshInterval);
    }

    render() {
        const View = !this.props.token ? AuthView : ActiveView;

        return (
            <div className={styles.root}>
                <div className={styles.content}>
                    {this.state.loading ? (
                        <Spinner />
                    ) : (
                        <View />
                    )}
                </div>
            </div>
        );
    }
}
