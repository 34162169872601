import React, {Component} from 'react';
import {autobind} from 'core-decorators';
import classnames from 'classnames';

import styles from './styles.css';

@autobind
class EditableText extends Component {
    state = {
        active: false,
        showHint: false,
        text: this.props.text || '',
    };

    static defaultProps = {
        underline: true,
    }

    render () {
        const {active, showHint, text} = this.state;
        const editableText = this.state.text;
        const vissibleText =  editableText || 'Click to add';
        return active ? (
            <div className={styles.root}>
                <input
                    value={editableText}
                    onBlur={this.handleDone}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    ref={element => this.input = element}
                    className={classnames(styles.text, styles.editing)}
                    />

            </div>
        ) : (
            <div className={classnames(styles.root, {[styles.underline]: this.props.underline || showHint})} onClick={this.toggleEditMode}>
                <div onMouseOver={this.showHint} onMouseOut={this.hideHint}  className={styles.text}>
                    {vissibleText}&nbsp;
                </div>
                <i className={classnames("material-icons", styles.icon)}>edit</i>
            </div>
        );
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.text) {
            this.setState({text: nextProps.text});
        }
    }

    showHint () {
        this.setState({showHint: true});
    }

    hideHint () {
        this.setState({showHint: false});
    }

    toggleEditMode () {
        this.setState({active: true}, () => {
            this.input.focus();
        });
    }

    handleKeyDown (e) {
        if (e.key === 'Enter' || e.keyCode === 13) this.handleDone();
    }

    handleChange ({target}) {
        this.setState({
            // TODO: clean input.
            text: target.value,
        });
    }

    handleDone () {
        this.setState({active: false, showHint: false, touched: false}, () => {
            this.props.onDoneEditing(this.state.text || '');
        });
    }
}

export default EditableText;
