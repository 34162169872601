/* eslint-disable react/jsx-no-bind */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {autobind, nonenumerable} from 'core-decorators';
import ReactSelect from 'react-select';

import {colors} from 'styles';
// { data, isDisabled, isFocused, isSelected }
const customStyles = {
    option: (provided, state) => ({
        ...provided,
    }),
    control: (provided,  {isFocused}) => ({
        ...provided,
        boxShadow: 0,
        '&:hover': {
            borderColor: colors.primaryDark1,
            borderWidth: '0 0 1px 0',
        },
        borderColor: colors.primaryDark2,
        borderRadius: 0,
        borderWidth: '0 0 1px 0',
        backgroundColor: 'rgba(0,0,0,0)',
    }),
    clearIndicator: (provided) => ({
        ...provided,
        color: colors.primaryDark2,
        stroke: 'none',
        '&:hover': {
            color: colors.primaryDark1,
        },
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: colors.primaryDark2,
        stroke: 'none',
        '&:hover': {
            color: colors.primaryDark1,
        },
    }),
    menu: (provided) => ({
        ...provided,
        backgroundColor: colors.grayLight4,
        border: 'none',
        borderRadius: 0,
    }),
    option: (provided, {isSelected}) => ({
        ...provided,
        backgroundColor: isSelected ? colors.primaryDark2 : colors.white,
        '&:hover': {
            color: colors.white,
            backgroundColor: colors.primaryDark1,
        }
    }),
    singleValue: (provided) => {
        return {
            ...provided,
            color: colors.primaryDark2,
        };
    }
  }

@autobind
export default class Select extends Component {
    static propTypes = {
        options: PropTypes.arrayOf(PropTypes.oneOfType(
            [
                PropTypes.shape({value: PropTypes.any.isRequired, label: PropTypes.string.isRequired}),
                PropTypes.string.isRequired
            ]
        )),
        value: PropTypes.any,
        placeholder: PropTypes.string,
        clearable: PropTypes.bool,
        emptyValue: PropTypes.any,
        disabled: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        onBlur: PropTypes.func,
    };

    static defaultProps = {
        emptyValue: '',
        clearable: true,
    };

    render() {
        const {placeholder, clearable, disabled} = this.props;
        const {options} = this.state;
        return (
            <ReactSelect
                styles={customStyles}
                value={this.getValue()}
                options={options}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                isClearable={clearable}
                isDisabled={disabled}
                placeholder={placeholder}
                loadingMessage={() => 'Loading...'}
                noOptionsMessage={() => 'No options available'}
            />
        );
    }

    componentWillMount() {
        let {options} = this.props;
        // ensure that all options are of the same type:
        const type = typeof options[0];
        for (const option of options) {
            if (typeof option !== type)
                throw new Error('Options should all be of the same type');
        }

        // build options if necesary.
        if (type === 'string') {
            options = options.map((option) => ({
                label: option,
                value: option,
            }));
        }

        this.setState({options});
    }

    handleBlur() {
        if (this.props.onBlur) this.props.onBlur();
    }

    handleChange(option) {
        if (option) {
            this.props.onChange(option.value, option);
        } else {
            this.props.onChange(this.props.emptyValue);
        }
        if (this.props.onBlur) this.props.onBlur();
    }

    getValue() {
        const {options} = this.state;
        const {value, emptyValue} = this.props;
        return options.find(option => option.value === value) || emptyValue;
    }
}
