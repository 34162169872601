import React, {Component} from 'react';
// import RedBox from 'redbox-react';
import * as Sentry from '@sentry/browser';


export default class ErrorBoundary extends Component {
    static getDerivedStateFromError(error) {
        return {error};
    }

    state = {
        error: null,
    };

    componentDidCatch(error, errorInfo) {
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    render() {
        if (this.state.error) {
            // return <RedBox error={this.state.error} />;
            return <div>error</div>;
        }
        return this.props.children;
    }
}
