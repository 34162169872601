import {createAction} from '../utils';


export const loadActiveOutputHandlers = createAction('ACTIVE_OUTPUT_HANDLERS:LOAD');
export const discardOutputHandlers = createAction('ACTIVE_OUTPUT_HANDLERS:DISCARD');

export const updateOutputHandlerProperty = createAction('ACTIVE_OUTPUT_HANDLERS:UPDATE_PROPERTY');

export const addOutputHandler = createAction('ACTIVE_OUTPUT_HANDLERS:ADD_HANDLER')
export const removeOutputHandler = createAction('ACTIVE_OUTPUT_HANDLERS:REMOVE_HANDLER');
