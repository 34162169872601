import React, {Component} from 'react';
import uuid from 'uuid';
import {connect} from 'react-redux';
import {autobind} from 'core-decorators';
import {bindActionCreators} from 'redux';

import {fromQueryString} from 'core/utils';
import {saveWorkflow} from 'core/store/workflows/actions';
import {duplicateWorkflow, createWorkflow} from 'core/store/active-workflow/actions';

import Spinner from 'components/spinner';
import WorkflowEditor from 'components/workflow-editor';

@connect(
    state => ({activeWorkflow: state.activeWorkflow}),
    dispatch => bindActionCreators({duplicateWorkflow, createWorkflow, saveWorkflow}, dispatch),
)
@autobind
export default class CreateWorkflow extends Component {
    state = {
        errors: [],
    };

    render() {
        if (!this.props.activeWorkflow) return <Spinner/>;

        return (
            <WorkflowEditor
                workflow={this.props.activeWorkflow}
                apiErrors={this.state.errors}
                onSave={this.handleSave}
            />
        );
    }

    componentWillMount() {
        const {duplicate} = fromQueryString(this.props.location.search.replace(/\?/, ''));
        if (duplicate) return this.props.duplicateWorkflow(duplicate);
        return this.props.createWorkflow();
    }


    async handleSave (workflow) {
        try {
            await this.props.saveWorkflow({isNew: true, ...workflow});
            return this.props.history.push('/');
        } catch (error) {
            if (error.data) return this.setState({errors: error.data});
            return this.setState({errors: ['Sorry, an error has occurred, please try again later.']});
        }
    }
}
