import {createStore, applyMiddleware} from 'redux';
import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const AUTH_SESSION_KEY = 'C24-WA-AUTH_SESSION';

const configureStore = (initialState = {}) => {
    const middleware = [thunk];

    if (process.env.NODE_ENV === 'development') middleware.push(createLogger({level: 'info', collapsed: true}));

    initialState =  getStateFormSession() || initialState;
    const store = createStore(rootReducer, initialState, applyMiddleware(...middleware));

    if (module.hot) module.hot.accept('./reducers', () => store.replaceReducer(rootReducer));

    store.subscribe(() => setStateInSession(store.getState()));

    return store;
};

const setStateInSession = state => {
    if (!window.sessionStorage) return;

    try {
        sessionStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(state.auth));
    } catch (error) {
        console.error(error);
    }
}

const getStateFormSession = () => {
    if (!window.sessionStorage) return;

    try {
        const serializedState = sessionStorage.getItem(AUTH_SESSION_KEY);
        if (serializedState === null) return undefined;
        return {auth: JSON.parse(serializedState)};
    } catch (error) {
        console.error(error);
        return undefined;
    }
}

export {configureStore};
