/* eslint-disable max-len */
import 'react';

import * as core from 'core';
import App from 'views/app';
import Home from 'views/home';
import ErrorPage from 'views/error-page';
import EditWorkflow from 'views/edit-workflow';
import CreateWorkflow from 'views/create-workflow';


core.initialize({
    ...CONFIG,
    views: [
        {name: 'home', path: '/', component: Home},
        {name: 'edit', path: '/edit/:workflowId', component: EditWorkflow},
        {name: 'create', path: '/create', component: CreateWorkflow},
        {name: 'error', path: '', component: ErrorPage},
    ],
});

core.render(App);
