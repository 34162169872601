import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import classnames from 'classnames';
import {autobind} from 'core-decorators';
import {Link} from 'react-router-dom';

import {logout} from 'core/store/auth/actions';

import styles from './styles.css';


@connect(
    state => ({
        token: state.auth.token,
        refererAddress: state.auth.refererAddress,
    }),
    dispatch => bindActionCreators({logout}, dispatch),
)
@autobind
export default class AppLayout extends Component {
    handleLogout() {
        this.props.logout();
    }

    render () {
        const {token, refererAddress} = this.props;

        return  (
            <div className={styles.root}>
                <div className={styles.header}>
                    <div className={styles.brand}>
                        <div className={styles.logo}/>
                        <Link to="/"><span className={styles.legend}>Workflows Administration</span></Link>
                    </div>
                    <div className={styles.links}>
                        {Boolean(refererAddress) && <a href={refererAddress} className={styles.legend}>OPS</a>}
                        {Boolean(token) && <div className={styles.legend} onClick={this.handleLogout}>Logout</div>}
                    </div>
                </div>
                <div className={styles.appContent}>{this.props.children}</div>
            </div>
        );
    }
}
