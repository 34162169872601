import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {updateActiveWorkflowProperty} from 'core/store/active-workflow/actions';

import Toggle from 'components/toggle';

import styles from './styles.css';

@connect(
    (state) => ({activeWorkflow: state.activeWorkflow}),
    (dispatch) => bindActionCreators({updateActiveWorkflowProperty}, dispatch),
)
class WorkflowDetail extends Component {
    render () {
        if (!this.props.workflow) return <p>Loading ...</p>

        const {actions, isActive} = this.props.workflow;

        const updateWorkflowIsActiveState = (value) => {
            this.props.updateActiveWorkflowProperty({
                value,
                property: 'isActive',
            });
        };

        return (
            <ul className={styles.root}>
                <li><label>Actions: </label> {actions.length}</li>
                <li><label>Active: </label><Toggle onChange={updateWorkflowIsActiveState} value={isActive}/></li>
            </ul>
        );
    }
}

export default WorkflowDetail;
