import React, {Component} from 'react';
import {connect} from 'react-redux';
import {autobind} from 'core-decorators';
import {bindActionCreators} from 'redux';

import {loadWorkflow} from 'core/store/active-workflow/actions';
import {saveWorkflow} from 'core/store/workflows/actions';

import Spinner from 'components/spinner';
import WorkflowEditor from 'components/workflow-editor';

@connect(
    state => ({activeWorkflow: state.activeWorkflow}),
    dispatch => bindActionCreators({loadWorkflow, saveWorkflow}, dispatch),
)
@autobind
export default class EditWorkflow extends Component {
    state = {
        errors: [],
    };

    render  () {
        if (!this.props.activeWorkflow) return <Spinner/>;

        return (
            <WorkflowEditor
                workflow={this.props.activeWorkflow}
                apiErrors={this.state.errors}
                onSave={this.handleSave}
            />
        );
    }

    componentWillMount () {
        const {params} = this.props.match;
        this.props.loadWorkflow(params.workflowId);
    }

    async handleSave () {
        try {
            await this.props.saveWorkflow(this.props.activeWorkflow);
            return this.props.history.push('/');
        } catch (error) {
            console.error(error);
            if (error.data) return this.setState({errors: error.data});
            return this.setState({errors: ['Sorry, an error has occurred, please try again later.']});
        }
    }
}
