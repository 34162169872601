import {createReducer} from '../utils';
import {login, logout, getToken, refreshToken, setRefererAddress} from './actions';

export default createReducer({token: null, errors: [], refererAddress: null}, {
    [login]: (state, {payload}) => {
        return {token: payload.token, errors: []};
    },
    [logout]: (state, {payload}) => {
        return {token: null, errors: []};
    },
    [getToken.failure]: (state, {payload: error}) => {
        return {...state, errors: error.message};
    },
    [refreshToken.failure]: (state, {payload: error}) => {
        return {
            ...state,
            token: null,
            errors: error.message
        };
    },
    [setRefererAddress]: (state, {payload}) => {
        return {...state, refererAddress: payload}
    }
});
