import React, {Component} from 'react';
import {hot} from 'react-hot-loader/root';

import globalStyles from 'styles/index.css'; // eslint-disable-line no-unused-vars
import styles from './styles.css';


@hot
export default class AppContainer extends Component {
    render() {
        return (
            <div className={styles.root}>
                {this.props.children}
            </div>
        );
    }
}
