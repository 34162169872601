import React, {Component} from 'react';

import styles from './styles.css';


export default class Spinner extends Component {
    render() {
        return (
            <div className={styles.root}>
                <div className={styles.spinner} />
            </div>
        );
    }
}
