import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {autobind} from 'core-decorators';

import styles from './styles.css';


@autobind
export default class Button extends Component {
    static propTypes = {
        color: PropTypes.oneOf(['primary', 'secondary', 'gray']),
        appearance: PropTypes.oneOf(['fill', 'outline', 'text']),
        size: PropTypes.oneOf(['sm', 'md', 'lg']),
        type: PropTypes.oneOf(['button', 'submit']),
        disabled: PropTypes.bool,
        children: PropTypes.node.isRequired,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        color: 'primary',
        appearance: 'fill',
        size: 'md',
        type: 'button',
    };

    render() {
        return (
            <button
                className={
                    classNames(
                        styles.root,
                        styles[`color-${this.props.color}`],
                        styles[`appearance-${this.props.appearance}`],
                        styles[`size-${this.props.size}`],
                        {[styles.disabled]: this.props.disabled},
                    )
                }
                onClick={this.handleClick}
                disabled={this.props.disabled}
                type={this.props.type}>
                {this.props.children}
            </button>
        );
    }

    handleClick() {
        if (this.props.onClick) this.props.onClick();
    }
}
