import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {autobind} from 'core-decorators';
import classNames from 'classnames';

import styles from './styles.css'

@autobind
export default class Toggle extends Component {
    static propTypes = {
        value: PropTypes.bool,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        value: undefined,
        disabled: false,
        onChange: undefined,
    };

    state = {
        focus: false,
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps.value !== this.props.value
            || nextProps.disabled !== this.props.disabled
            || nextProps.onChange !== this.props.onChange
            || nextState.focus !== this.state.focus;
    }

    handleChange() {
        if (this.props.onChange) this.props.onChange(!this.props.value);
    }

    handleKeyPress(event) {
        if (this.props.onChange && event.key === 'Enter') this.props.onChange(!this.props.value);
    }

    handleFocus() {
        this.setState({focus: true});
    }

    handleBlur() {
        this.setState({focus: false});
    }

    render() {
        const {value, disabled} = this.props;
        return (
            <div
                className={classNames(
                    styles.root,
                    {
                        [styles.disabled]: disabled,
                        [styles.checked]: value,
                        [styles.focused]: this.state.focus,
                    },
                )}
            >
                <input
                    type='checkbox'
                    checked={value}
                    disabled={disabled}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onKeyPress={this.handleKeyPress}
                    className={styles.input}
                />
                <div className={styles.circle} />
            </div>
        );
    }
}

