import uuid from 'uuid';
import camelize from 'camelize';

const decamelize = (str, separator) => {
  separator = typeof separator === 'undefined' ? '_' : separator;
  return str
    .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
    .toLowerCase();
};

export const UUID = () => uuid.v4().replace(/-/g, '');

export const getApiUrl = path => CONFIG.apiUrl.concat(path);

export const camelizeObjectKeys = object => {
  return Object.keys(object).reduce((camelized, key) => {
    return Object.assign(camelized, {[camelize(key)]: object[key]});
  }, {});
};

export const camelizeWorkflow = rawWorkflow => {
  const workflow = camelizeObjectKeys({...rawWorkflow});
  workflow.data = undefined;
  workflow.actions = rawWorkflow.data.map((action) => {
    action.outputHandlers = action.output_handlers.map((handler) => {
      return camelizeObjectKeys(handler);
    });
    return camelizeObjectKeys(action);
  })
  return workflow;
};

export const decamelizeObjectKeys = object => {
  return Object.keys(object).reduce((camelized, key) => {
    return Object.assign(camelized, {[decamelize(key)]: object[key]});
  }, {});
};

export const decamelizeWorkflow = camelizedWorkflow => {
  const workflow = decamelizeObjectKeys(camelizedWorkflow);
  workflow.actions = undefined;
  workflow.data = camelizedWorkflow.actions.map((action) => {
    action.output_handlers = action.outputHandlers.map((handler) => {
      return decamelizeObjectKeys(handler);
    });
    action.clientId = undefined;
    return decamelizeObjectKeys(action);
  });
  return workflow;
};
