import React, {Component} from 'react';
import PropTypes from 'prop-types';

import styles from './styles.css';


export default class ErrorPage extends Component {
    static propTypes = {
        status: PropTypes.number,
    };

    static defaultProps = {
        status: 404,
    };

    render() {
        const errors = {
            404: {
                code: 404,
                name: 'Page not found',
            },
            403: {
                code: 403,
                name: 'Permission denied',
            },
            400: {
                code: 500,
                name: 'Internal error',
            },
        };
        const error = errors[this.props.status];
        return (
            <div className={styles.root}>
                <p className={styles.code}>{error.code}</p>
                <p className={styles.name}>{error.name}</p>
            </div>
        );
    }
}
