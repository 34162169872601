import React, {Component} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Select from 'components/forms/select';
import {autobind} from 'core-decorators';

import {updateActiveActionProperty} from 'core/store/active-action-config/actions';

import {
    addOutputHandler,
    removeOutputHandler,
    loadActiveOutputHandlers,
    updateOutputHandlerProperty,
} from 'core/store/active-output-handlers/actions';

import Toggle from 'components/toggle';
import Button from 'components/button';
import EditableText from 'components/editable-text';

import styles from './styles.css';


@connect(
    (state) => {
        return {
            availableActionConfigs: state.actionConfigs,
            activeHandlers: state.activeOutputHandlers,
        };
    },
    dispatch => bindActionCreators({
        addOutputHandler,
        loadActiveOutputHandlers,
        updateActiveActionProperty,
    }, dispatch),
)
@autobind
export default class OutputHandlerEditorList extends Component {
    static propTypes = {
        workflowArgs: PropTypes.object,
        handlers: PropTypes.array.isRequired,
    };

    componentWillMount () {
        this.props.loadActiveOutputHandlers(this.props.handlers);
    }

    render () {
        const {availableActionConfigs, parentActionId} = this.props;
        const actionConfig = availableActionConfigs[parentActionId];

        const availableOutputs = actionConfig ? actionConfig.states : [];
        const renderWorkflowArgsEditor = actionConfig && Object.keys(actionConfig.workflowArgs).length !== 0;

        return (
            <div>
                {this.props.activeHandlers.map((handler, index) => (
                    <OutputHandlerEditor
                        handler={handler}
                        availableOutputs={availableOutputs}
                        key={[handler.outputValue, index].join('_')}/>
                ))}
                <div className={styles.addButtonContainer}>
                    <Button onClick={this.props.addOutputHandler} size='md' appearance='text'>Add Handler</Button>
                </div>
                {renderWorkflowArgsEditor && <hr />}
                {renderWorkflowArgsEditor && this.renderWorkflowArgsEditor(actionConfig.workflowArgs)}
            </div>
        );
    }

    renderWorkflowArgsEditor (args) {
        const {workflowArgs} = this.props;
        return (
            <div className={styles.workflowArgsContainer}>
                <label>Workflow Arguments:</label>
                {Object.keys(args).map(argName => {
                    const handler = this.updateWorkflowArgs(argName);
                    const argValue = workflowArgs[argName];
                    const InputComponent = typeof argValue === 'boolean'
                        ? <Toggle onChange={handler} value={argValue} />
                        : <EditableText onDoneEditing={handler} text={argValue}/>;
                    return (
                        <div className={styles.workflowArg} key={argName}>
                            <label>{argName}</label>
                            {InputComponent}
                        </div>
                    );
                })}
            </div>
        );
    }

    updateWorkflowArgs (argName) {
        return (value) => {
            this.props.updateActiveActionProperty({
                value,
                argName,
                property: 'workflowArgs',
            });
        }
    }
}

@connect(
    state => ({
        availableActionConfigs: state.actionConfigs,
    }),
    dispatch => bindActionCreators({
        removeOutputHandler,
        updateOutputHandlerProperty,
    }, dispatch),
)
@autobind
class OutputHandlerEditor extends Component {
    state = {
        shade: false,
        outputSelection: null,
        actionSelection: null,
    };

    render () {
        const {availableActionConfigs, availableOutputs} = this.props;
        const {identifier, valueSelection, actionSelection} = this.state;

        const actionOptions = !valueSelection ? [] : Object.keys(availableActionConfigs);

        return (
            <div className={classnames(styles.singleRoot, {[styles.shade]: this.state.shade})}>
                <div className={styles.userInput}>
                    <Select
                        value={valueSelection}
                        options={availableOutputs}
                        onChange={this.selectOutputValue}
                    />
                </div>
                <i className={classnames('material-icons', styles.separator, styles.icon)}>chevron_right</i>
                <div className={styles.userInput}>
                    <Select
                        value={actionSelection}
                        options={actionOptions}
                        onChange={this.selectAction}
                    />
                </div>
                <div className={classnames(styles.separator, styles.text)}>:</div>
                <div className={styles.userInput}>
                    <EditableText onDoneEditing={this.updateOutputIdentifier} text={identifier}/>
                </div>
                <span className={styles.remove} onClick={this.removeHandler} onMouseOver={this.toggleShade} onMouseOut={this.toggleShade}>
                    <i className={classnames('material-icons', styles.icon, styles.remove)}>clear</i>
                </span>
            </div>
        );
    }

    componentWillMount() {
        this.updateSelection(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.updateSelection(nextProps);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {handler} = this.props;
        const {handler: nextHandler} = nextProps;

        const {identifier, outputValueOptions, shade} = this.state;
        const {
            shade: nextShade,
            identifier: nextIdentifier,
            outputValueOptions: nextoutputValueOptions
        } = nextState;

        return shade !== nextShade
            || identifier !== nextIdentifier
            || handler.actionId !== nextHandler.actionId
            || outputValueOptions !== nextoutputValueOptions
            || handler.identifier !== nextHandler.identifier
            || handler.outputValue !== nextHandler.outputValue;

    }

    updateSelection({handler}) {
        const {availableOutputs} = this.props;
        const {actionId, outputValue, identifier} = handler;

        const valueSelection = availableOutputs.find((state) => state === outputValue);
        let actionSelection = null;

        if (valueSelection) {
            actionSelection = Object.keys(this.props.availableActionConfigs).find((action) => {
                return action === actionId;
            });
        }

        this.setState({valueSelection, actionSelection, identifier});
    }

    updateOutputIdentifier(value) {
        const {clientId} = this.props.handler;
        this.props.updateOutputHandlerProperty({clientId, value, property: 'identifier'});
    }

    selectOutputValue(value) {
        const {clientId} = this.props.handler;
        this.props.updateOutputHandlerProperty({clientId, value, property: 'outputValue'});
    }

    selectAction(value) {
        const {clientId} = this.props.handler;
        this.props.updateOutputHandlerProperty({clientId, value, property: 'actionId'});
    }

    removeHandler() {
        this.props.removeOutputHandler({clientId: this.props.handler.clientId});
    }

    toggleShade() {
        this.setState({shade: !this.state.shade});
    }
}
