import uuid from 'uuid';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {autobind} from 'core-decorators';
import {bindActionCreators} from 'redux';

import {setIsEquivalent} from 'core/utils';
import {fetchActionConfigs} from 'core/store/action-configs/actions';
import {saveWorkflow, removeWorkflow} from 'core/store/workflows/actions';
import {discardOutputHandlers} from 'core/store/active-output-handlers/actions';
import {loadActionConfig, discardActionConfig, updateActiveActionProperty} from 'core/store/active-action-config/actions';

import {
    loadWorkflow,
    unloadWorkflow,
    saveActionConfig,
    addActionToConfig,
    removeActionConfig,
    updateActiveWorkflowProperty,
} from 'core/store/active-workflow/actions';

import Table from 'components/table';
import Layout from 'components/layout';
import Button from  'components/button';
import Spinner from 'components/spinner';
import Select from 'components/forms/select';
import ErrorList from 'components/error-list';
import PageHeader from 'components/page-header';
import EditableText from 'components/editable-text';
import OutputHandler from 'components/output-handler';
import WorkflowDetail from 'components/workflow-detail';

import styles from './styles.css';

import columns from './columns.json';
import {isTemplateElement} from '@babel/types';

@connect(
    state => ({
        availableActionConfigs: state.actionConfigs,
        activeActionConfig: state.activeActionConfig,
        activeOutputHandlers: state.activeOutputHandlers,
    }),
    dispatch => bindActionCreators({
        saveWorkflow,
        loadWorkflow,
        unloadWorkflow,
        removeWorkflow,
        saveActionConfig,
        loadActionConfig,
        addActionToConfig,
        removeActionConfig,
        fetchActionConfigs,
        discardActionConfig,
        discardOutputHandlers,
        updateActiveWorkflowProperty,
        updateActiveActionProperty,
    }, dispatch),
)
@autobind
export default class WorkflowEditor extends Component {
    state = {
        rowErrors: {},
        workflowErrors: [],
        newAction: null,
        missingActionConfigs: [],
        missingActionOutputs: [],
    };

    static defaultProps = {
        apiErrors: [],
    };

    render () {
        const {workflow, apiErrors, activeActionConfig} =  this.props;
        const {missingActionConfigs, missingActionOutputs, workflowErrors} = this.state;

        const title = workflow && (
            <EditableText
                underline={false}
                text={workflow.name}
                onDoneEditing={this.updateWorkflowName}
            />
        );

        const controlsDisabled = Boolean(activeActionConfig);

        return (
            <Layout>
                <PageHeader title={title}>
                    <WorkflowDetail workflow={workflow}/>
                </PageHeader>
                    {!workflow && <Spinner/>}
                    <div className={styles.viewContent}>
                        <ErrorList errors={[...workflowErrors, ...apiErrors]}>
                            These errors were found in the workflow top level config:
                        </ErrorList>
                        {this.renderConfigEditor()}
                        <ErrorList errors={missingActionConfigs}>
                            Following actions were declared as an output state for another action, but where not found in the Actions List:
                        </ErrorList>
                        <ErrorList errors={missingActionOutputs}>
                            Following actions were found in the actions list, but were not declared as a state for any previous actions:
                        </ErrorList>
                        <div className={styles.workflowActions}>
                            <div className={styles.label}>Actions:</div>
                            <Table columns={columns} rows={this.renderRows()} onEmptyStateClick={this.addNewAction} />
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        <div>
                            <Button onClick={this.addNewAction} disabled={Boolean(activeActionConfig)}>Add Action</Button>
                        </div>
                        <div>
                            <Button onClick={this.resetWorkflow} disabled={controlsDisabled}>Discard</Button>
                            <Button onClick={this.commitWorkflowChanges} color='secondary' disabled={controlsDisabled}>Save</Button>
                        </div>
                    </div>
            </Layout>
        );
    }

    renderRows () {
        const {workflow, activeActionConfig} = this.props;
        return workflow.actions.map((action, index) => {
            const displayIndex = index + 1;
            const editing = activeActionConfig && action.clientId === activeActionConfig.clientId;
            return editing ? this.getEditableRow(displayIndex) : this.getRow(action, displayIndex);
        });
    }

    renderErrors (key) {
        const errorsForKey = this.state.rowErrors[key];
        if (!errorsForKey || !errorsForKey.length) return null;
        return <ErrorList errors={errorsForKey} />;
    }

    renderConfigEditor () {
        const {workflow} = this.props;
        const fields = [
            {key: 'description', label: 'Description'},
            {key: 'startingAction', label: 'Starting Action'},
            {key: 'chunkedWorkflowId', label: 'Chunked Workflow Id'},
            {key: 'workflowId', label: 'Workflow Id'}
        ];
        return (
            <div className={styles.topLevelConfig}>
                {fields.map((item) => {
                    const onDone = value => this.props.updateActiveWorkflowProperty({value, property: item.key});
                    return (
                        <div className={styles.editor} key={item.key}>
                            <div className={styles.content}>
                                <div className={styles.label}>{item.label}</div>
                                <EditableText text={workflow[item.key]} onDoneEditing={onDone}/>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    componentWillMount () {
        this.props.fetchActionConfigs();
    }

    componentWillUnmount () {
        this.discardChanges();
    }

    getRow (action, index) {
        const disable = Boolean(this.props.activeActionConfig);

        const loadAction = () => this.props.loadActionConfig(action.clientId);
        const removeAction = () => this.props.removeActionConfig(action.clientId);

        const buttons = (
            <div className={styles.buttonsContainer}>
                <Button disabled={disable} onClick={loadAction}>Edit</Button>
                <Button disabled={disable} onClick={removeAction}>Delete</Button>
            </div>
        );

        const [actionId, identifier] = action.actionId.split(':');

        const ActionIdDisplay = (
            <p>
                {actionId}
                {identifier && <strong>&nbsp;:{identifier}</strong>}
            </p>
        );

        const OutputHandlers = (
            <div className={styles.outputHandlers}>
                {action.outputHandlers.map((outputHandler, index) => {
                    const key = [outputHandler.outputValue, index].join('_');
                    return <OutputHandler.Pill key={key} outputHandler={outputHandler}/>
                })}
            </div>
        );

        return {
            id: ActionIdDisplay,
            buttons,
            index: <p>{index}</p>,
            outputHandlers: OutputHandlers,
        }
    }

    getEditableRow (index)  {
        const {activeActionConfig, availableActionConfigs, updateActiveActionProperty} = this.props;

        const options = Object.keys(availableActionConfigs).map(
            actionConfig => ({value: actionConfig, label: actionConfig})
        );

        const [actionId, identifier] = activeActionConfig.actionId.split(':');

        const updateActionId = value => updateActiveActionProperty({
            property: 'actionId',
            value: identifier ? value.concat(':', identifier) : value,
        });

        const updateActionIdentifier = value => updateActiveActionProperty({
            property: 'actionId',
            value: value ? actionId.concat(':', value) : actionId,
        });

        const ActionIdEditor = (
            <div >
                <div className={styles.actionIdEditor}>
                    <div className={styles.selector}>
                        <Select
                            options={options}
                            value={actionId}
                            onChange={updateActionId}
                        />
                    </div>
                    <div className={styles.separator}>:</div>
                    <div className={styles.identifier}>
                        <EditableText
                            text={identifier}
                            onDoneEditing={updateActionIdentifier}
                        />
                    </div>
                </div>
                {this.renderErrors('actionId')}
            </div>
        );

        const {outputHandlers, workflowArgs} = activeActionConfig || {};

        const OutputHandlers = (
            <div>
                <OutputHandler.EditorList
                    parentActionId={actionId}
                    handlers={outputHandlers}
                    workflowArgs={workflowArgs || {}}
                />
                {this.renderErrors('outputHandlers')}
            </div>
        );

        const buttons = (
            <div className={styles.buttonsContainer}>
                <Button onClick={this.discardActionChanges}>Cancel</Button>
                <Button onClick={this.saveActionConfigChanges}>Done</Button>
            </div>
        );

        return {
            index: <p>{index}</p>,
            buttons,
            id: ActionIdEditor,
            outputHandlers: OutputHandlers,
        };
    }

    addNewAction () {
        const newAction = uuid.v4();
        this.setState({newAction});
        this.props.addActionToConfig(newAction);
        this.props.loadActionConfig(newAction);
    }

    discardActionChanges() {
        this.props.discardActionConfig();
        this.props.discardOutputHandlers();
        const newState = {rowErrors: []}
        if (this.state.newAction) {
            this.props.removeActionConfig(this.state.newAction);
            newState.newAction = null;
        }
        this.setState(newState);
    }

    discardChanges () {
        this.discardActionChanges();
        this.props.unloadWorkflow();
    }

    resetWorkflow() {
        this.discardChanges();
        this.props.loadWorkflow(this.props.workflow.id);
    }

    validateActionConfig ({activeActionConfig, activeOutputHandlers}) {
        const errorsByKey = {}
        const addError = (key, message) => {
            if (!errorsByKey[key]) errorsByKey[key] = [];
            errorsByKey[key].push(message);
        };

        const requiredFields = ['actionId'];
        for (const key of requiredFields) {
            if (!activeActionConfig[key]) addError(key, 'This is field is required.');
        }

        for (const {outputValue, actionId} of activeOutputHandlers) {
            if (!outputValue || !actionId) {
                addError('outputHandlers', 'One of the output handlers configuration is incomplete.');
                break;
            }
        }

        return errorsByKey;
    }

    saveActionConfigChanges () {
        const {activeActionConfig, activeOutputHandlers} = this.props;
        const updatedAction = {...activeActionConfig, outputHandlers: [...activeOutputHandlers]};

        const validationErrors = this.validateActionConfig({activeActionConfig, activeOutputHandlers});
        if (Object.keys(validationErrors).length)  return this.setState({rowErrors: validationErrors});

        const cleanState = {errors: {}, newAction: null};

        this.setState(cleanState, () => {
            this.props.saveActionConfig({
                updatedAction,
                clientId: activeActionConfig.clientId,
            });
            this.props.discardOutputHandlers();
            this.props.discardActionConfig();
        });
    }

    updateWorkflowName (value) {
        this.props.updateActiveWorkflowProperty({value, property: 'name'});
    }

    validateWorkflowConfig ({workflow}) {
        const {startingAction, actions} = workflow;

        const allOutputActions = new Set();
        const allConfiguredActions = new Set();

        for (const {actionId, outputHandlers} of actions) {
            allConfiguredActions.add(actionId);
            outputHandlers.forEach(({actionId, identifier}) => {
                allOutputActions.add(actionId + (identifier ? ':'.concat(identifier) : ''));
            });
        }

        const errors = {};
        const workflowErrors = [];

        const requiredFields = [{key: 'name', label: 'Name'}, {key: 'startingAction', label: 'Starting Action'}];
        requiredFields.forEach(field => {
            if (!workflow[field.key]) workflowErrors.push(`${field.label} is required.`);
        });

        if (!workflow.actions.length) workflowErrors.push('Its necessary to add at least one action.');

        Object.assign(errors, {workflowErrors});
        let missingActionConfigs = [];
        let missingActionOutputs = [];

        if (!setIsEquivalent(allOutputActions, allConfiguredActions)) {
            // Validate that every action in the handlers exists in the list of actions.
            missingActionConfigs = [...allOutputActions].filter(
                element => !allConfiguredActions.has(element)
            );
            // Validate that every action in the list is either the starting action
            // or is referenced by some outputHandler.
            missingActionOutputs = [...allConfiguredActions].filter(
                element => !allOutputActions.has(element) && element !== startingAction
            );
        }

        Object.assign(errors, {missingActionConfigs, missingActionOutputs});

        return errors;
    }

    commitWorkflowChanges () {
        const {workflow} = this.props;
        const validationData = this.validateWorkflowConfig({workflow});
        const {missingActionConfigs, missingActionOutputs, workflowErrors} = validationData;
        this.setState({missingActionConfigs, missingActionOutputs, workflowErrors}, () => {
            if (!missingActionConfigs.length && !missingActionOutputs.length && !workflowErrors.length) {
                this.props.onSave(workflow);
            }
        });
    }
}
