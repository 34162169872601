import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {autobind} from 'core-decorators';

import {getToken} from 'core/store/auth/actions';

import Button from 'components/button';
import AppLayout from 'components/layout';
import ErrorList from 'components/error-list';
import PageHeader from 'components/page-header';

import styles from './styles.css';

@connect(
    (state) => ({authErrors: state.auth.errors}),
    (dispatch) => bindActionCreators({getToken}, dispatch),
)
@autobind
export default class AuthView extends Component {
    static defaultProps = {
        authErrors: [],
    };

    state = {
        errors: [],
        username: '',
        password: '',
    };

    render() {
        const errors = [];

        if (this.props.authErrors.length) errors.push(this.props.authErrors);
        if (this.state.errors.length) errors.concat(this.state.errors);

        const {username, password} = this.state;
        const disabled = !username || !password;

        return (
            <AppLayout >
                <div className={styles.root}>
                    <h2>Login to continue:</h2>
                    <div className={styles.inputGroup}>
                        <label>Username:</label>
                        <input placeholder='Type your username...' onChange={this.handleChange('username')}/>
                    </div>
                    <div className={styles.inputGroup}>
                        <label>Password:</label>
                        <input
                            type='password'
                            placeholder='Type your password...'
                            onChange={this.handleChange('password')}
                            onKeyDown={this.handleKeyDown}
                        />
                    </div>
                    <ErrorList errors={errors} />
                    <div className={styles.buttonsContainer}>
                        <Button disabled={disabled} onClick={this.login}>Login</Button>
                    </div>
                </div>
            </AppLayout>
        );
    }

    handleChange(key) {
        return ({target}) => this.setState({[key]: target.value});
    }

    handleKeyDown(e) {
        if (e.key === 'Enter' || e.keyCode === 13) return this.login();
    }

    login() {
        const {username, password} = this.state;

        const errors = [];
        if (!username) errors.push('Username field is required');
        if (!password) errors.push('Password field is required');

        this.setState({errors}, () => {
            if (!errors.length) this.props.getToken({username, password});
        });
    }

}
